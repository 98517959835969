import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getScrapeSummaryList,
  selectAllScrapeSummary,
  selectErrors
} from './scrapeSummarySlice'


const ScrapeSummaryList = ({
  summaryList,
  handleSummaryRowClick
}) => {

  return (
    <tbody>
      {summaryList.map((summary) => (
        <tr key={summary.id} onClick={(e) => handleSummaryRowClick(e, summary)}>
          <td className="text-capitalize">{summary.scrape_service}</td>
          <td>{summary.timestamp_from_sns_notif}</td>
          <td>{summary.summary_job_results_count}</td>
        </tr>
      ))}
    </tbody>
  )
}

export default ScrapeSummaryList;