import React, { useEffect, useState } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUserAuthenticated } from '../../../components/auth/authSlice';
import axios from 'axios';


const ResetPasswordForm = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setState] = useState({
        password: ""
    });
    const { password } = state;  
    
    const isUserAuthenticated = useSelector((state) => selectIsUserAuthenticated(state))
    const [hasValidToken, setHasValidToken] = useState()
    const [passwordResetSubmitted, setPasswordResetSubmitted] = useState(false)
    const [errors, setErrors] = useState({})

    const resetPasswordFormSubmit = (e) => {
        e.preventDefault();
        applyNewPassword();
    };

    const onChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    useEffect(() => {
        fetchIsValidToken();
    }, [hasValidToken])

    async function fetchIsValidToken() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        const body = {
            token: searchParams.get('token')
        }

        try {
            const response = await axios.post(process.env.REACT_APP_USERS_SERVICE_API_DOMAIN + `/users/api/password-reset/validate-token/`, body, config)
            setHasValidToken(true)
        } catch(err) {
            setHasValidToken(false)
        }
    }

    async function applyNewPassword() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const resetPasswordObj = {
            password: state.password,
            token: searchParams.get('token')
        }
        try {
            const response = await axios.post(process.env.REACT_APP_USERS_SERVICE_API_DOMAIN + `/users/api/password-reset/confirm/`, resetPasswordObj, config)
            setPasswordResetSubmitted(true)
            setErrors({})
        } catch(err) {
            setPasswordResetSubmitted(false)
            setErrors(err.response.data)
        }
    }

    if (isUserAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    if( passwordResetSubmitted ) {
        return <Navigate to="/login" />;
    }

    let pageBody;
    if( hasValidToken ) {
        pageBody = (
            <form onSubmit={resetPasswordFormSubmit}>
                <div className="mb-3">
                    <label className="form-label" htmlFor="password">New Password *</label>
                    <input
                        type="password"
                        className={errors.password ? "form-control is-invalid" : "form-control"}
                        id="password"
                        name="password"
                        onChange={onChange}
                        value={password}
                    />
                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                </div>

                <div className="mb-3">
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </div>
            </form>
        );
    } else {
        pageBody = (
            <p className="text-center">Unable to process your request. Please contact an administrator for additional assistance.</p>
        );
    }

    return (
        <div className="col-md-6 m-auto">
            <div className="card card-body mt-5">
                <h2 className="text-center">Reset Password Form</h2>
                {pageBody}
            </div>
        </div>
    )
}

export default ResetPasswordForm