import React from 'react'
import { Link } from 'react-router-dom';


const MyUserAccountIndex = () => {
    return (
        <div className="row">
            <div className="col-lg-8 d-flex flex-column">
                <div className="row flex-grow">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card card-rounded">
                            <div className="card-body">
                                <div className="d-sm-flex justify-content-between align-items-start">
                                    <div>
                                        <h2 className="me-2 fw-bold">My User Account</h2>
                                    </div>
                                </div>
                                <div className="d-sm-flex align-items-center mt-1 justify-content-between">
                                    <div className="">
                                        <p>
                                            <Link to="/my-account/edit/">
                                                Edit My User Account
                                            </Link>
                                        </p>
                                        <p>
                                            <Link to="/my-account/change-password/">
                                                Update My User Account Password
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyUserAccountIndex