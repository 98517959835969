import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { 
    selectAllIndustries,
    getIndustryList,
    updateIndustry,
    deleteIndustry,
    selectErrors,
    clearErrors
} from './industriesSlice'


const IndustryList = () => {
    const dispatch = useDispatch();
    const industries = useSelector(selectAllIndustries);
    const errors = useSelector(selectErrors);
    const [isEditing, setIsEditing] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [state, setState] = useState({
        name: "",
    });
    
    useEffect(() => {
      dispatch(getIndustryList());
    }, []);

    const handleDeleteClick = (e, recordId) => {
        e.preventDefault()
        dispatch(deleteIndustry(recordId))
    }

    const handleEditClick = (e, recordId) => {
        setIsEditing(true);
        setEditingId(recordId);
        setState({ name: industries.find((record) => record.id === recordId).name });
    }

    const handleSaveClick = async (e, recordId) => {
        e.preventDefault();
        const resultAction = await dispatch(updateIndustry({ id: recordId, name: state.name }));
        if (updateIndustry.fulfilled.match(resultAction)) {
            handleCancelEditClick(e, recordId);
        }
    }

    const handleCancelEditClick = (e, recordId) => {
        e.preventDefault();
        setIsEditing(false);
        setEditingId(null);
        setState({ name: "" });
        dispatch(clearErrors());
    }

    const onChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    let industryListTableBody;
    if( !industries.length ) {
        industryListTableBody = (
            <tr>
                <td colSpan="4">No industries have been added. Create an industry to get started</td>
            </tr>
        );
    } else {
        industryListTableBody = (
            industries.map((record) => (
                isEditing && editingId === record.id ? (
                    <tr key={record.id}>
                        <td></td>
                        <td>
                            <div className="mb-0">
                                <input
                                    type="text"
                                    className={errors.updateIndustry && errors.updateIndustry[record.id]?.name ? "form-control is-invalid" : "form-control"}
                                    id="name"
                                    name="name"
                                    onChange={onChange}
                                    value={state.name}
                                />
                                {errors.updateIndustry && errors.updateIndustry[record.id]?.name && <div className="invalid-feedback">{errors.updateIndustry[record.id].name}</div>}
                            </div>
                        </td>
                        <td>
                            <button 
                                onClick={(e) => handleSaveClick(e, record.id)}
                                className="btn btn-success btn-sm">
                                Save
                            </button>
                        </td>
                        <td>
                            <button
                                onClick={(e) => handleCancelEditClick(e, record.id)}
                                className="btn btn-danger btn-sm">
                                Cancel
                            </button>
                        </td>
                    </tr>
                ) : (
                    <tr key={record.id}>
                        <td></td>
                        <td>
                            <div className="d-flex">
                                <div>
                                    <p className="mb-0">{record.name}</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <button 
                                onClick={(e) => handleEditClick(e, record.id)}
                                className="btn btn-primary btn-sm">
                                Edit
                            </button>
                        </td>
                        <td>
                            <button
                                onClick={(e) => handleDeleteClick(e, record.id)}
                                className="btn btn-danger btn-sm">
                                Delete
                            </button>
                        </td>
                    </tr>
                )
            ))
        );
    }

    return (
        <div className="col-lg-8 d-flex flex-column">
            <div className="row flex-grow">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card card-rounded">
                        <div className="card-body">
                            <div className="d-sm-flex justify-content-between align-items-start">
                                <div>
                                    <h2 className="card-title card-title-dash">Industry List</h2>
                                    <p className="card-subtitle card-subtitle-dash">You have {industries.length} industries</p>
                                </div>
                                <div>
                                    <Link
                                        className="btn btn-primary btn-sm text-white mb-0 me-0"
                                        to="/industries/create">
                                        Create Industry
                                    </Link>
                                </div>
                            </div>
                            <div className="table-responsive mt-1">
                                <table className="table select-table table-white">
                                    <thead>
                                        <tr>
                                            <th />
                                            <th>Name</th>
                                            <th />
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {industryListTableBody}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IndustryList