import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid'
import { createIndeedSearch, selectErrors } from './indeedSlice'


const emptySearch = {
  name: '',
  type: 'indeed',
  description: '',
  keywords: '',
  location: '',
  timeframe: '',
  radius: '',
  work_location: '',
  is_deactivated: '0'
}

const CreateIndeed = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState(emptySearch)
  const errors = useSelector(selectErrors);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch(prevState => ({ ...prevState, [name]: value }));
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const newSearch = search

    if(newSearch.timeframe === '') {
      newSearch.timeframe = null
    }

    if(newSearch.radius === '') {
      newSearch.radius = null
    }

    const resultAction = await dispatch(createIndeedSearch(newSearch));
    if (createIndeedSearch.fulfilled.match(resultAction)) {
      navigate('/indeed')
    }
  }

  return (
    <div className="col-lg-8 d-flex flex-column">
      <div className="row flex-grow">
        <div className="col-12 grid-margin stretch-card">
          <div className="card card-rounded">
            <div className="card-body">
              <h2>Create Indeed Search</h2>
              <form onSubmit={handleFormSubmit}>

                <div className="mb-3">
                  <label className="form-label" htmlFor="name">Name</label>
                  <input
                    type="text"
                    className={errors.createIndeedSearch && errors.createIndeedSearch.name ? "form-control is-invalid" : "form-control"}
                    id="name"
                    name="name"
                    value={search.name}
                    onChange={handleInputChange}
                  />
                  {errors.createIndeedSearch && errors.createIndeedSearch.name && <div className="invalid-feedback">{errors.createIndeedSearch.name}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="description">Description</label>
                  <textarea
                    className={errors.createIndeedSearch && errors.createIndeedSearch.description ? "form-control is-invalid" : "form-control"}
                    id="description"
                    name="description"
                    value={search.description}
                    onChange={handleInputChange}
                    rows={2}
                  />
                  {errors.createIndeedSearch && errors.createIndeedSearch.description && <div className="invalid-feedback">{errors.createIndeedSearch.description}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="keywords">Search Term (Keywords)</label>
                  <input
                    type="text"
                    className={errors.createIndeedSearch && errors.createIndeedSearch.keywords ? "form-control is-invalid" : "form-control"}
                    id="keywords"
                    name="keywords"
                    value={search.keywords}
                    onChange={handleInputChange}
                  />
                  {errors.createIndeedSearch && errors.createIndeedSearch.keywords && <div className="invalid-feedback">{errors.createIndeedSearch.keywords}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="location">Location of Company (not work location)</label>
                  <input
                    type="text"
                    className={errors.createIndeedSearch && errors.createIndeedSearch.location ? "form-control is-invalid" : "form-control"}
                    id="location"
                    name="location"
                    value={search.location}
                    onChange={handleInputChange}
                  />
                  {errors.createIndeedSearch && errors.createIndeedSearch.location && <div className="invalid-feedback">{errors.createIndeedSearch.location}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="timeframe">Select Days Since Job was Posted</label>
                  <select
                    className={errors.createIndeedSearch && errors.createIndeedSearch.timeframe ? "form-control is-invalid" : "form-control"}
                    id="timeframe"
                    name="timeframe"
                    value={search.timeframe || ''}
                    onChange={handleInputChange}
                  >
                    <option value="">Choose One</option>
                    {Array.from({ length: 14 }, (_, i) => i + 1).map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                  {errors.createIndeedSearch && errors.createIndeedSearch.timeframe && <div className="invalid-feedback">{errors.createIndeedSearch.timeframe}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="radius">Radius (Miles from Location)</label>
                  <input
                    type="number"
                    className={errors.createIndeedSearch && errors.createIndeedSearch.radius ? "form-control is-invalid" : "form-control"}
                    id="radius"
                    name="radius"
                    value={search.radius}
                    onChange={handleInputChange}
                  />
                  {errors.createIndeedSearch && errors.createIndeedSearch.radius && <div className="invalid-feedback">{errors.createIndeedSearch.radius}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="work_location">Work Location (remote, hybrid, none)</label>
                  <select
                    className={errors.createIndeedSearch && errors.createIndeedSearch.work_location ? "form-control is-invalid" : "form-control"}
                    id="work_location"
                    name="work_location"
                    value={search.work_location || ''}
                    onChange={handleInputChange}
                  >
                    <option value="">Not Specified</option>
                    <option value="remote">Remote</option>
                    <option value="hybrid">Hybrid</option>
                  </select>
                  {errors.createIndeedSearch && errors.createIndeedSearch.work_location && <div className="invalid-feedback">{errors.createIndeedSearch.work_location}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="is_deactivated">Is Deactivated?</label>
                  <div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_deactivated"
                        id="is_deactivated_yes"
                        data-testid="is_deactivated_yes"
                        value="1"
                        checked={search.is_deactivated === '1'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_deactivated_yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_deactivated"
                        id="is_deactivated_no"
                        data-testid="is_deactivated_no"
                        value="0"
                        checked={search.is_deactivated === '0'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_deactivated_no">
                        No
                      </label>
                    </div>
                  </div>
                  {errors.createIndeedSearch && errors.createIndeedSearch.is_deactivated && <div className="invalid-feedback">{errors.createIndeedSearch.is_deactivated}</div>}
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateIndeed




