import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateIndeedSearch, getIndeedSearchById, selectIndeedSearchById, selectErrors } from './indeedSlice'
import { v4 as uuidv4 } from 'uuid'


const EditIndeed = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const [state, setState] = useState({
      name: '',
      type: 'indeed',
      description: '',
      keywords: '',
      location: '',
      timeframe: '',
      radius: '',
      work_location: '',
      is_deactivated: '0'
  });
  const search = useSelector((state) => selectIndeedSearchById(state, id))
  const errors = useSelector(selectErrors);


  useEffect(() => {
    dispatch(getIndeedSearchById(id));
  }, []);

  useEffect(() => {
    if( search ) {
      setState({ ...search });
    }
  }, [search]);


  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const search = state

    if(search.timeframe === '') {
      search.timeframe = null
    }

    if(search.radius === '') {
      search.radius = null
    }

    const resultAction = await dispatch(updateIndeedSearch(search));

    if (updateIndeedSearch.fulfilled.match(resultAction)) {
        navigate('/indeed')
    }
  }

  return (
    <div className="col-lg-8 d-flex flex-column">
      <div className="row flex-grow">
        <div className="col-12 grid-margin stretch-card">
          <div className="card card-rounded">
            <div className="card-body">
              <h2>Edit Indeed Search</h2>
              <form onSubmit={handleFormSubmit}>

                <div className="mb-3">
                  <label className="form-label" htmlFor="name">Name</label>
                  <input
                    type="text"
                    className={errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.name ? "form-control is-invalid" : "form-control"}
                    id="name"
                    name="name"
                    value={state.name}
                    onChange={handleInputChange}
                  />
                  {errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.name && <div className="invalid-feedback">{errors.updateIndeedSearch[id].name}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="description">Description</label>
                  <textarea
                    className={errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.description ? "form-control is-invalid" : "form-control"}
                    name="description"
                    value={state.description}
                    onChange={handleInputChange}
                    rows={2}
                  />
                  {errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.description && <div className="invalid-feedback">{errors.updateIndeedSearch[id].description}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="keywords">Search Term (Keywords)</label>
                  <input
                    type="text"
                    className={errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.keywords ? "form-control is-invalid" : "form-control"}
                    id="keywords"
                    name="keywords"
                    value={state.keywords}
                    onChange={handleInputChange}
                  />
                  {errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.keywords && <div className="invalid-feedback">{errors.updateIndeedSearch[id].keywords}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="location">Location of Company (not work location)</label>
                  <input
                    type="text"
                    className={errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.location ? "form-control is-invalid" : "form-control"}
                    id="location"
                    name="location"
                    value={state.location}
                    onChange={handleInputChange}
                  />
                  {errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.location && <div className="invalid-feedback">{errors.updateIndeedSearch[id].location}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="timeframe">Select Days Since Job was Posted</label>
                  <select
                    className={errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.timeframe ? "form-control is-invalid" : "form-control"}
                    id="timeframe"
                    name="timeframe"
                    value={state.timeframe || ''}
                    onChange={handleInputChange}
                  >
                    <option value="">Choose One</option>
                    {Array.from({ length: 14 }, (_, i) => i + 1).map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                  </select>
                  {errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.timeframe && <div className="invalid-feedback">{errors.updateIndeedSearch[id].timeframe}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="radius">Radius (Miles from Location)</label>
                  <input
                    type="number"
                    className={errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.radius ? "form-control is-invalid" : "form-control"}
                    id="radius"
                    name="radius"
                    value={state.radius}
                    onChange={handleInputChange}
                  />
                  {errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.radius && <div className="invalid-feedback">{errors.updateIndeedSearch[id].radius}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="work_location">Work Location (remote, hybrid, none)</label>
                  <select
                    className={errors.updateIndeedSearch && errors.updateIndeedSearch.work_location ? "form-control is-invalid" : "form-control"}
                    id="work_location"
                    name="work_location"
                    value={state.work_location || ''}
                    onChange={handleInputChange}
                  >
                    <option value="">Not Specified</option>
                    <option value="remote">Remote</option>
                    <option value="hybrid">Hybrid</option>
                  </select>
                  {errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.work_location && <div className="invalid-feedback">{errors.updateIndeedSearch[id].work_location}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="is_deactivated">Is Deactivated?</label>
                  <div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_deactivated"
                        id="is_deactivated_yes"
                        value="1"
                        checked={state.is_deactivated == '1'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_deactivated_yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_deactivated"
                        id="is_deactivated_no"
                        value="0"
                        checked={state.is_deactivated == '0'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_deactivated_no">
                        No
                      </label>
                    </div>
                  </div>
                  {errors.updateIndeedSearch && errors.updateIndeedSearch[id]?.is_deactivated && <div className="invalid-feedback">{errors.updateIndeedSearch[id].is_deactivated}</div>}
                </div>

                <div className="pt-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditIndeed