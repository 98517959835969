import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectErrors } from './jobBoardsSlice'

const JobBoard = ({
  jobBoardId,
  jobBoard,
  handleJobBoardInputChange
}) => {

  const { type, resource_slug, url, section_to_check } = jobBoard;
  const errors = useSelector(selectErrors);

  return (
    <div className="job-board">
      <div className="mb-3">
        <label className="form-label" htmlFor="type">Type</label>
        <select
          className={errors.type ? "form-control is-invalid" : "form-control"}
          id="type"
          name="type"
          value={type || ''}
          onChange={(e) => handleJobBoardInputChange(e, jobBoard)}
        >
          <option value="greenhouse">Greenhouse</option>
          <option value="website">Company Website</option>
        </select>
        {errors.type && <div className="invalid-feedback">{errors.type}</div>}
      </div>

      <div className="mb-3">
        <label className="form-label" htmlFor="resource_slug">Resource Slug</label>
        <input
          type="text"
          className={errors.resource_slug ? "form-control is-invalid" : "form-control"}
          id="resource_slug"
          name="resource_slug"
          value={resource_slug || ''}
          onChange={(e) => handleJobBoardInputChange(e, jobBoard)}
        />
        {errors.resource_slug && <div className="invalid-feedback">{errors.resource_slug}</div>}
      </div>

      <div className="mb-3">
        <label className="form-label" htmlFor="url">Job Board URL</label>
        <input
          type="text"
          className={errors.url ? "form-control is-invalid" : "form-control"}
          id="url"
          name="url"
          value={url || ''}
          onChange={(e) => handleJobBoardInputChange(e, jobBoard)}
        />
        {errors.url && <div className="invalid-feedback">{errors.url}</div>}
      </div>

      <div className="mb-3">
        <label className="form-label" htmlFor="section_to_check">Section to Check</label>
        <input
          type="text"
          className={errors.section_to_check ? "form-control is-invalid" : "form-control"}
          id="section_to_check"
          name="section_to_check"
          value={section_to_check || ''}
          onChange={(e) => handleJobBoardInputChange(e, jobBoard)}
        />
        {errors.section_to_check && <div className="invalid-feedback">{errors.section_to_check}</div>}
      </div>
    </div>
  )
}

export default JobBoard;