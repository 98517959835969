import React from 'react'
import IndustryList from './IndustryList'
import RightSidebar from './RightSidebar'


const IndustryIndex = () => {
    return (
        <div className="row">
            <IndustryList />
            <RightSidebar />
        </div>
    );
}

export default IndustryIndex