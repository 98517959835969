import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getJobPostById, selectJobPostByJobPostId, selectErrors } from './jobPostsSlice'
import { selectAllJobPostKeywords } from './jobPostKeywordsSlice';
import { selectActiveAuthUser } from '../../components/auth/authSlice';


const JobPostDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const currentUser = useSelector((state) => selectActiveAuthUser(state))
  const jobPost = useSelector((state) => selectJobPostByJobPostId(state, id))
  const keywords = useSelector(selectAllJobPostKeywords);
  const errors = useSelector(selectErrors);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const record = {
      job_post_id: id,
      user_id: currentUser.id
    }
    dispatch(getJobPostById(record))
    .then(() => {
      setIsLoading(false);
    })
  }, [dispatch]);

  if (isLoading) {
    return (
      <div>
        <h1>Loading...</h1>
        <p>{jobPost?.id}</p>
      </div>
    )
  } else if( Object.keys(errors).length > 0 ) {
    return (
      <div key={jobPost?.id}>
        <h1>{errors.not_found}</h1>
      </div>
    )

  } else {
    return (
      <div className="row">
        <div className="col-lg-8 d-flex flex-column">
          <div className="row flex-grow">
            <div className="col-12 grid-margin stretch-card">
              <div className="card card-rounded">
                <div className="card-body">
                  <div className="d-sm-flex justify-content-between align-items-start">
                    <div>
                      <h1 className="card-title card-title-dash">Job Details</h1>
                    </div>
                  </div>

                  <div key={jobPost?.id} >
                    <p>Company Name: {jobPost.company_name}</p>
                    <p>Job Title: {jobPost.job_post_title}</p>
                    <p>Job Post Id: {jobPost.job_post_id}</p>
                    <p>Date Job was Posted: {jobPost.job_post_date_posted}</p>
                    <p>Date Job was Found: {jobPost.record_created_at}</p>
                    <hr />
                    <h4><strong>Full Job Description</strong></h4>
                    <p className="display-linebreak">{jobPost.job_post_description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 d-flex flex-column">
          <div className="row flex-grow">
            <div className="col-12 grid-margin stretch-card">
              <div className="card card-rounded">
                <div className="card-body">
                  <div className="d-sm-flex justify-content-between align-items-start">
                    <div>
                      <h3 className="card-title card-title-dash">Keywords</h3>
                    </div>
                  </div>
                  <div className="">
                      {keywords.length > 0 &&
                        <ul>
                          {keywords.map((keyword, index) => (
                            <li key={id}>{keyword.term} -- {keyword.rank}</li>
                          ))}
                        </ul>
                      }
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default JobPostDetail;