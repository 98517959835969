import axios from 'axios';
import {
  current,
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import { getJobPostById } from './jobPostsSlice'


// Configure axios
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_USERS_SERVICE_API_DOMAIN
});

// Define the entity adapter
const jobPostKeywordsAdapter = createEntityAdapter({
    selectId: ({ id }) => id,
})

// Define the initial state using the adapter
const initialState = jobPostKeywordsAdapter.getInitialState({
    status: 'idle',
    errors: {},
    ids: [],
})

// Create the slice
const jobPostKeywordsSlice = createSlice({
  name: 'jobPostKeywords',
  initialState,
  reducers: {
      clearErrors: (state) => {
          state.errors = {}
      }
  },
  extraReducers(builder) {
    builder
      // Get Company By Id
      .addCase(getJobPostById.fulfilled, (state, action) => {
        const keywords = action.payload.keywords
        jobPostKeywordsAdapter.upsertMany(state, keywords)
        state.errors = {}
        state.status = 'idle'
      })
  }
})

export const { addJobBoard } = jobPostKeywordsSlice.actions

export default jobPostKeywordsSlice.reducer

export const {
  selectAll: selectAllJobPostKeywords,
  selectById: selectJobPostKeywordsById,
  selectIds: selectJobPostKeywordsIds,
} = jobPostKeywordsAdapter.getSelectors((state) => state.jobPostKeywordsSlice)

// SELECTOR: Get validation errors
export const selectErrors = (state) => {
  return state.jobPostKeywordsSlice.errors
}
