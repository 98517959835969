import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadUserNew, logout, selectIsUserAuthenticated } from '../auth/authSlice';

const LeftSidebar = () => {
    let dispatch = useDispatch();
    const user = useSelector(store => store.authSlice.user);
    const isUserAuthenticated = useSelector((state) => selectIsUserAuthenticated(state))

    const handleLogoutClick = (e) => {
        e.preventDefault();
        dispatch(logout());
    };

    const authLinks = (
        <Fragment>
            <ul className="nav flex-column">
                <li className="nav-item">
                    <Link to="/dashboard" className="nav-link d-flex align-items-center gap-2 active" aria-current="page">
                        Dashboard
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/industries" className="nav-link d-flex align-items-center gap-2" aria-current="page">
                        Industries
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/companies" className="nav-link d-flex align-items-center gap-2" aria-current="page">
                        Greenhouse Companies
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/indeed" className="nav-link d-flex align-items-center gap-2" aria-current="page">
                        Indeed Searches
                    </Link>
                </li>
            </ul>

            {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">
                <span>A Section</span>
                <Link to="#" className="link-secondary" aria-label="Add a new report"></Link>
            </h6>
            <ul className="nav flex-column mb-auto">
                <li className="nav-item">
                    <Link to="#" className="nav-link d-flex align-items-center gap-2" aria-current="page">
                        A Link
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="#" className="nav-link d-flex align-items-center gap-2" aria-current="page">
                        A Link
                    </Link>
                </li>
            </ul> */}

            <hr className="my-3" />

            <ul className="nav flex-column mb-auto">
                <li className="nav-item">
                    <Link to="/my-account" className="nav-link d-flex align-items-center gap-2" aria-current="page">
                        My Account
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="#" className="nav-link d-flex align-items-center gap-2" aria-current="page" onClick={handleLogoutClick}>
                        Log out
                    </Link>
                </li>
            </ul>
        </Fragment>
    );

    const guestLinks = (
        <ul className="nav flex-column mb-auto">
            <li className="nav-item">
                <Link to="/login" className="nav-link d-flex align-items-center gap-2" aria-controls="login">
                    <i className="mdi mdi-grid-large menu-icon"></i>
                    <span className="menu-title">Login</span>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="#" className="nav-link d-flex align-items-center gap-2" aria-controls="register">
                    <i className="mdi mdi-grid-large menu-icon"></i>
                    <span className="menu-title">Another Action</span>
                </Link>
            </li>
        </ul>
    );

    return (
        <div className="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
            <div className="offcanvas-md offcanvas-end bg-body-tertiary"
                id="sidebarMenu"
                tabIndex="-1"
                aria-labelledby="sidebarMenuLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="sidebarMenuLabel">Prospect App</h5>

                    <button type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        data-bs-target="#sidebarMenu"
                        aria-label="Close">
                    </button>
                </div>

                <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                    { isUserAuthenticated ? authLinks : guestLinks }
                </div>
            </div>
        </div>
    );
}

export default LeftSidebar;
