import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { selectActiveAuthUser, changeUserPassword, selectErrors } from '../../components/auth/authSlice';

const EditMyUserAccountPassword = () => {
    let dispatch = useDispatch()
    let navigate = useNavigate()

    const currentUser = useSelector((state) => selectActiveAuthUser(state))
    const errors = useSelector(selectErrors);
    const [state, setState] = useState({
        old_password: "",
        new_password1: "",
        new_password2: "",
    });
    const { old_password, new_password1, new_password2 } = state;  

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const payload = state 
        state.id = currentUser.id
        const resultAction = await dispatch(changeUserPassword(payload));
        if (!changeUserPassword.rejected.match(resultAction)) {
            navigate('/my-account');
        }
    };

    return (
        <div className="row">
            <div className="col-lg-8 d-flex flex-column">
                <div className="row flex-grow">
                    <div className="col-12 grid-margin stretch-card">
                        <div className="card card-rounded">
                            <div className="card-body">
                                <div className="d-sm-flex justify-content-between align-items-start">
                                <div>
                                    <h2 className="me-2 fw-bold">Change User Account Password</h2>
                                </div>
                                </div>
                                <div className="d-sm-flex align-items-center mt-1 justify-content-between">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="old_password">Old Password</label>
                                            <input
                                                className={errors.old_password ? "form-control is-invalid" : "form-control"}
                                                id="old_password"
                                                type="password"
                                                name="old_password"
                                                onChange={handleInputChange} // update state with onChange
                                                value={old_password || ""}/>
                                            {errors.old_password && <div className="invalid-feedback">{errors.old_password}</div>}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="new_password1">New Password</label>
                                            <input
                                                className={errors.new_password1 ? "form-control is-invalid" : "form-control"}
                                                id="new_password1"
                                                type="password"
                                                name="new_password1"
                                                onChange={handleInputChange} // update state with onChange
                                                value={new_password1 || ""}/>
                                            {errors.new_password1 && <div className="invalid-feedback">{errors.new_password1}</div>}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="new_password2">Confirm New Password</label>
                                            <input
                                                className={errors.new_password2 ? "form-control is-invalid" : "form-control"}
                                                id="new_password2"
                                                type="password"
                                                name="new_password2"
                                                onChange={handleInputChange} // update state with onChange
                                                value={new_password2 || ""}/>
                                            {errors.new_password2 && <div className="invalid-feedback">{errors.new_password2}</div>}
                                        </div>
                                        <div className="mb-3">
                                            <button type="submit" className="btn btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditMyUserAccountPassword