import axios from 'axios';
import { tokenConfig } from '../../components/auth/authSlice';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'


// Configure axios
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SCRAPE_SERVICE_API_DOMAIN
});

// Define the entity adapter
const jobPostsAdapter = createEntityAdapter({
    selectId: ({ id }) => id,
})

// Define the initial state using the adapter
const initialState = jobPostsAdapter.getInitialState({
    status: 'idle',
    errors: {},
    ids: [],
})


// Get a single company
export const getJobPostById = createAsyncThunk(
  'jobposts/getJobPostById',
  async (record, thunkAPI) => {
      const { getState } = thunkAPI;
      try {
          const response = await axiosInstance.post(`/api/job-post/by-job-post-id/`, record)
          return await response.data
      } catch(err) {
          return thunkAPI.rejectWithValue(err.response.data)
      }
  }
)


// Create the slice
const jobPostsSlice = createSlice({
  name: 'jobPosts',
  initialState,
  reducers: {
      clearErrors: (state) => {
          state.errors = {}
      }
  },
  extraReducers(builder) {
      builder
          // Get Job Post by id
          .addCase(getJobPostById.fulfilled, (state, action) => {
            const job_post = action.payload.data
            jobPostsAdapter.upsertOne(state, job_post)
            state.errors = {}
            state.status = 'idle'
          })
          .addCase(getJobPostById.pending, (state, action) => {
              state.status = 'loading'
          })
          .addCase(getJobPostById.rejected, (state, action) => {
            state.errors = {'not_found': 'Job Post not found.'}
            state.status = 'idle'
          })

  }
})

export default jobPostsSlice.reducer

// Export the actions
export const { clearErrors } = jobPostsSlice.actions

export const {
  selectAll: selectAllJobPosts,
  selectById: selectJobPostById,
} = jobPostsAdapter.getSelectors((state) => state.jobPostsSlice)

// SELECTOR: Get validation errors
export const selectErrors = (state) => {
  return state.jobPostsSlice.errors
}

// SELECTOR: Get job post by the job_post_id attribute
export const selectJobPostByJobPostId = (state, job_post_id) =>
  Object.values(state.jobPostsSlice.entities).find(
    (jobPost) => jobPost.job_post_id === job_post_id
  );