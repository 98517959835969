import React from 'react'
import CompanyList from './CompanyList'
import RightSidebar from './RightSidebar'


const CompanyIndex = () => {
  return (
    <div className="row">
      <CompanyList />
      <RightSidebar />
    </div>
  );
}

export default CompanyIndex