import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

//Styles
//import logo from '../assets/images/logo.svg';
import '../assets/styles/main.scss';

// PrivateRoute component to redirect users away from private routes if they are not authenticated
import PrivateRoute from '../components/common/PrivateRoute';

//Components
import LeftSidebar from '../components/layouts/LeftSidebar';
import TopNavbar from '../components/layouts/TopNavbar';
import Dashboard from '../features/dashboard/Index';
import Login from '../features/users/Login';
import RequestPasswordResetForm from '../features/users/passwordReset/RequestPasswordResetForm';
import ResetPasswordForm from '../features/users/passwordReset/ResetPasswordForm';
// import CategoriesList from '../features/categories/CategoriesList';
import IndustryIndex from '../features/industries/IndustryIndex';
import CompanyIndex from '../features/companies/CompanyIndex';
import CreateCompany from '../features/companies/CreateCompany';
import EditCompany from '../features/companies/EditCompany';
import IndeedIndex from '../features/indeed/IndeedIndex';
import CreateIndeed from '../features/indeed/CreateIndeed';
import EditIndeed from '../features/indeed/EditIndeed';
import MyUserAccountIndex from '../features/myAccount/MyAccountIndex';
import EditMyUserAccount from '../features/myAccount/EditMyUserAccount';
import EditMyUserAccountPassword from '../features/myAccount/EditMyUserAccountPassword';
import JobPostDetail from '../features/jobPosts/JobPostDetail';


function App() {
    return (
        <Router>
            <Fragment>
                <TopNavbar />
                <div className="container-fluid">
                    <div className="row">
                        <LeftSidebar />
                        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-4">
                            <Routes>
                                <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}/>
                                <Route path="/industries" element={<PrivateRoute><IndustryIndex /></PrivateRoute>}/>
                                <Route path="/companies" element={<PrivateRoute><CompanyIndex /></PrivateRoute>}/>
                                <Route path="/companies/create" element={<PrivateRoute><CreateCompany /></PrivateRoute>}/>
                                <Route path="/companies/edit/:id" element={<PrivateRoute><EditCompany /></PrivateRoute>}/>

                                <Route path="/indeed" element={<PrivateRoute><IndeedIndex /></PrivateRoute>}/>
                                <Route path="/indeed/create" element={<PrivateRoute><CreateIndeed /></PrivateRoute>}/>
                                <Route path="/indeed/edit/:id" element={<PrivateRoute><EditIndeed /></PrivateRoute>}/>

                                <Route path="/job-posts/view/:id" element={<PrivateRoute><JobPostDetail /></PrivateRoute>}/>

                                <Route path="/my-account" element={<PrivateRoute><MyUserAccountIndex /></PrivateRoute>}/>
                                <Route path="/my-account/edit" element={<PrivateRoute><EditMyUserAccount /></PrivateRoute>}/>
                                <Route path="/my-account/change-password" element={<PrivateRoute><EditMyUserAccountPassword /></PrivateRoute>}/>

                                <Route exact path="/" element={<Login />} />
                                <Route exact path="/login" element={<Login />} />
                                <Route exact path="/password-reset" element={<RequestPasswordResetForm />} />
                                <Route exact path="/password-reset/confirm" element={<ResetPasswordForm />} />
                            </Routes>
                        </main>
                    </div>
                </div>
            </Fragment>
        </Router>
    );
}

export default App;
