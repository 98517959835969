import React from 'react'
import IndeedList from './IndeedList'


const IndeedIndex = () => {
  return (
    <div className="row">
      <IndeedList />
    </div>
  );
}

export default IndeedIndex