import React, { Component } from 'react';
import { connect } from 'react-redux';

const LoadingScreen = () => {
    return (
        <div className="d-flex flex-column flex-grow-1 vh-100 overflow-auto">      
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    );
}

export default LoadingScreen;