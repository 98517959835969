import axios from 'axios';
import { tokenConfig } from '../../components/auth/authSlice';
import {
  current,
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import { getScrapeSummaryList } from './scrapeSummarySlice'
import { v4 as uuidv4 } from 'uuid'


// Configure axios
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_USERS_SERVICE_API_DOMAIN
});

// Define the entity adapter
const summaryJobResultsAdapter = createEntityAdapter({
    selectId: ({ id }) => id,
})

// Define the initial state using the adapter
const initialState = summaryJobResultsAdapter.getInitialState({
    status: 'idle',
    errors: {},
    ids: [],
})


// Create the slice
const summaryJobResultsSlice = createSlice({
  name: 'summaryJobResults',
  initialState,
  reducers: {
      clearErrors: (state) => {
          state.errors = {}
      }
  },
  extraReducers(builder) {
      builder
          // Get Scrape Summary List
          .addCase(getScrapeSummaryList.fulfilled, (state, action) => {
            const scrapesList = action.payload.map((scrape) =>
              scrape.summary_job_results.map((job) => ({ ...job, scrapeSummaryId: scrape.id }))
            )
            .flat()
            summaryJobResultsAdapter.upsertMany(state, scrapesList)
            state.errors = {}
            state.status = 'idle'
          })
          .addCase(getScrapeSummaryList.pending, (state, action) => {
            state.errors = {}
            state.status = 'loading'
          })
          .addCase(getScrapeSummaryList.rejected, (state, action) => {
            state.errors = {}
            state.status = 'idle'
          })
  }
})

export default summaryJobResultsSlice.reducer

// Export the actions
export const { clearErrors } = summaryJobResultsSlice.actions

export const {
  selectAll: selectAllSummaryJobResults,
  selectById: selectSummaryJobResultsById,
} = summaryJobResultsAdapter.getSelectors((state) => state.summaryJobResultsSlice)

// SELECTOR: Get validation errors
export const selectErrors = (state) => {
  return state.summaryJobResultsSlice.errors
}

export const selectJobPostRecordsBySummary = (state, summary) => {
  if( summary !== null ) {
    const entitiesArray = Object.values(state.summaryJobResultsSlice.entities);
    return entitiesArray.filter(record => record.scrapeSummaryId === summary.id);
  }
  return [];
}