import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid'
import { createCompany, selectErrors } from './companiesSlice'
import { getIndustryList } from '../industries/industriesSlice'
import JobBoardList from './JobBoardList'


const emptyCompany = {
  name: '',
  industry: '',
  website_url: '',
  is_unicorn: '0',
  is_deactivated: '0',
  description: ''
}

const emptyJobBoard = {
  type: "",
  resource_slug: "",
  url: "",
  section_to_check: ""
}

const CreateCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [company, setCompany] = useState(emptyCompany)
  const errors = useSelector(selectErrors);
  const industries = useSelector(state => state.industriesSlice.entities)
  const [jobBoards, setJobBoards] = useState([emptyJobBoard])

  useEffect(() => {
    dispatch(getIndustryList());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompany(prevState => ({ ...prevState, [name]: value }));
  }

  const handleJobBoardInputChange = (e, jobBoard) => {
    let { name, value } = e.target;

    const newList = jobBoards.map((jb) => {
      if( jb.id === jobBoard.id) {
        return { ...jb, [name]: value }
      }
      return jb
    })
    setJobBoards( newList )
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const newCompany = company
    newCompany.job_boards = jobBoards

    const resultAction = await dispatch(createCompany(newCompany));
    if (createCompany.fulfilled.match(resultAction)) {
      navigate('/companies')
    }
  }

  return (
    <div className="col-lg-8 d-flex flex-column">
      <div className="row flex-grow">
        <div className="col-12 grid-margin stretch-card">
          <div className="card card-rounded">
            <div className="card-body">
              <h2>Create a Company</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="name">Name</label>
                  <input
                    type="text"
                    className={errors.createCompany && errors.createCompany.name ? "form-control is-invalid" : "form-control"}
                    id="name"
                    name="name"
                    value={company.name}
                    onChange={handleInputChange}
                  />
                  {errors.createCompany && errors.createCompany.name && <div className="invalid-feedback">{errors.createCompany.name}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="industry">Industry</label>
                  <select
                    className={errors.createCompany && errors.createCompany.industry ? "form-control is-invalid" : "form-control"}
                    id="industry"
                    name="industry"
                    value={company.industry}
                    onChange={handleInputChange}
                  >
                    <option value="">Not Assigned</option>
                    {Object.values(industries).map(industry => (
                      <option key={industry.id} value={industry.id}>
                        {industry.name}
                      </option>
                    ))}
                  </select>
                  {errors.createCompany && errors.createCompany.industry && <div className="invalid-feedback">{errors.createCompany.industry}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="website_url">Company Website</label>
                  <input
                    type="text"
                    className={errors.createCompany && errors.createCompany.website_url ? "form-control is-invalid" : "form-control"}
                    id="website_url"
                    name="website_url"
                    value={company.website_url}
                    onChange={handleInputChange}
                  />
                  {errors.createCompany && errors.createCompany.website_url && <div className="invalid-feedback">{errors.createCompany.website_url}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="is_unicorn">Is Unicorn?</label>
                  <div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_unicorn"
                        id="is_unicorn_yes"
                        data-testid="is_unicorn_yes"
                        value="1"
                        checked={company.is_unicorn === '1'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_unicorn_yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_unicorn"
                        id="is_unicorn_no"
                        data-testid="is_unicorn_no"
                        value="0"
                        checked={company.is_unicorn === '0'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_unicorn_no">
                        No
                      </label>
                    </div>
                  </div>
                  {errors.createCompany && errors.createCompany.is_unicorn && <div className="invalid-feedback">{errors.createCompany.is_unicorn}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="is_deactivated">Is Deactivated?</label>
                  <div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_deactivated"
                        id="is_deactivated_yes"
                        data-testid="is_deactivated_yes"
                        value="1"
                        checked={company.is_deactivated === '1'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_deactivated_yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_deactivated"
                        id="is_deactivated_no"
                        data-testid="is_deactivated_no"
                        value="0"
                        checked={company.is_deactivated === '0'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_deactivated_no">
                        No
                      </label>
                    </div>
                  </div>
                  {errors.createCompany && errors.createCompany.is_deactivated && <div className="invalid-feedback">{errors.createCompany.is_deactivated}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="description">Description</label>
                  <textarea
                    className={errors.createCompany && errors.createCompany.description ? "form-control is-invalid" : "form-control"}
                    id="description"
                    name="description"
                    value={company.description}
                    onChange={handleInputChange}
                    rows={4}
                  />
                  {errors.createCompany && errors.createCompany.description && <div className="invalid-feedback">{errors.createCompany.description}</div>}
                </div>


                <JobBoardList
                  jobBoardsList={jobBoards}
                  handleJobBoardInputChange={handleJobBoardInputChange}
                />


                <div className="form-group">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCompany