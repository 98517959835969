import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { selectActiveAuthUser, updateUserInfo, selectErrors } from '../../components/auth/authSlice';

const EditMyUserAccount = () => {
  let dispatch = useDispatch()
  let navigate = useNavigate()

  const currentUser = useSelector((state) => selectActiveAuthUser(state))
  const errors = useSelector(selectErrors);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: ""
  });
  const { first_name, last_name, email, username } = state;  

  useEffect(() => {
    if( currentUser ) {
      setState({ ...currentUser });
    } 
  }, [currentUser]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const resultAction = await dispatch(updateUserInfo(state));
    if (!updateUserInfo.rejected.match(resultAction)) {
      navigate('/my-account');
    }
  };

  return (
    <div className="row">
      <div className="col-lg-8 d-flex flex-column">
        <div className="row flex-grow">
          <div className="col-12 grid-margin stretch-card">
            <div className="card card-rounded">
              <div className="card-body">
                <div className="d-sm-flex justify-content-between align-items-start">
                  <div>
                    <h2 className="me-2 fw-bold">Edit My User Account</h2>
                  </div>
                </div>
                <div className="d-sm-flex align-items-center mt-1 justify-content-between">
                  <form onSubmit={handleFormSubmit}>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="first_name">First Name</label>
                      <input
                        className={errors.first_name ? "form-control is-invalid" : "form-control"}
                        id="first_name"
                        type="text"
                        name="first_name"
                        onChange={handleInputChange} // update state with onChange
                        value={first_name || ""}/>
                      {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="last_name">Last Name</label>
                      <input
                        className={errors.last_name ? "form-control is-invalid" : "form-control"}
                        id="last_name"
                        type="text"
                        name="last_name"
                        onChange={handleInputChange} // update state with onChange
                        value={last_name || ""}/>
                      {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="email">Email</label>
                      <input
                        className={errors.email ? "form-control is-invalid" : "form-control"}
                        id="email"
                        type="text"
                        name="email"
                        onChange={handleInputChange} // update state with onChange
                        value={email || ""}/>
                      {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="username">Username</label>
                      <input
                        className={errors.username ? "form-control is-invalid" : "form-control"}
                        id="username"
                        type="text"
                        name="username"
                        onChange={handleInputChange} // update state with onChange
                        value={username || ""}/>
                      {errors.username && <div className="invalid-feedback">{errors.username}</div>}
                    </div>

                    <div className="mb-3">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditMyUserAccount