import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  scrapeAllCompanies
} from './companiesSlice'


const RightSidebar = () => {
  const dispatch = useDispatch();

  const handleScrapeAllClick = (e) => {
    e.preventDefault()
    dispatch(scrapeAllCompanies())
  }

  return (
    <div className="col-lg-4 d-flex flex-column">
      <div className="row flex-grow">
        <div className="col-md-6 col-lg-12 grid-margin">
          <div className="card card-rounded">
            <div className="card-body">
              <div className="row">
                <h3>Actions</h3>

                <button
                  onClick={(e) => handleScrapeAllClick(e)}
                  className="btn btn-secondary btn-sm">
                  Scrape All
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightSidebar