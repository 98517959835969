import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import { Provider } from "react-redux"
import store from './app/store';
import { loadUserNew } from './components/auth/authSlice';
//import reportWebVitals from './reportWebVitals';


async function start() {
    store.dispatch(loadUserNew());

    const container = document.getElementById('root');
    const root = ReactDOM.createRoot(container);
    root.render(
        <Provider store={store}>
            <App />
        </Provider>
    );
}
start();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
