import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  selectAllIndeedSearches,
  getIndeedSearchList,
  deleteIndeedSearch,
  scrapeIndeedSearch
} from './indeedSlice'


const IndeedList = () => {
  const dispatch = useDispatch();
  const indeed_searches = useSelector(selectAllIndeedSearches);

  useEffect(() => {
    console.log('useEffect - getIndeedSearchList')
    dispatch(getIndeedSearchList());
  }, []);

  const handleDeleteClick = (e, recordId) => {
    e.preventDefault()
    dispatch(deleteIndeedSearch(recordId))
  }

  const handleScrapeClick = (e, recordId) => {
    e.preventDefault()
    let record = { 'record_id': recordId }
    dispatch(scrapeIndeedSearch(record))
  }

  let indeedListTableBody;
  if( !indeed_searches.length ) {
    indeedListTableBody = (
      <tr>
        <td colSpan="6">No Indeed searches have been added. Create a search to get started</td>
      </tr>
    );
  } else {
    indeedListTableBody = (
      indeed_searches.map((record) => (
        <tr key={record.id}>
          <td></td>
          <td>{record.id}</td>
          <td>
            <div className="d-flex">
              <div>
                <h6>{record.name}</h6>
                <p>{record.description}</p>
              </div>
            </div>
          </td>
          <td>
            <button
              onClick={(e) => handleScrapeClick(e, record.id)}
              className="btn btn-secondary btn-sm">
              Scrape
            </button>
          </td>
          <td>
            <Link to={"/indeed/edit/"+record.id} state={{ id: "" }} className="btn btn-primary btn-sm">
              Edit
            </Link>
          </td>
          <td>
            <button
              onClick={(e) => handleDeleteClick(e, record.id)}
              className="btn btn-danger btn-sm">
              Delete
            </button>
          </td>
        </tr>
      ))
    );
  }

  return (
    <div className="col-lg-8 d-flex flex-column">
      <div className="row flex-grow">
        <div className="col-12 grid-margin stretch-card">
          <div className="card card-rounded">
            <div className="card-body">
              <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                  <h2 className="card-title card-title-dash">Indeed Search List</h2>
                  <p className="card-subtitle card-subtitle-dash">You have {indeed_searches.length} searches</p>
                </div>
                <div>
                  <Link
                    className="btn btn-primary btn-sm text-white mb-0 me-0"
                    to="/indeed/create">
                    Create Search
                  </Link>
                </div>
              </div>
              <div className="table-responsive mt-1">
                <table className="table select-table table-white">
                  <thead>
                    <tr>
                      <th />
                      <th>ID</th>
                      <th>Name</th>
                      <th />
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {indeedListTableBody}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndeedList