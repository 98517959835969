import axios from 'axios';
import {
  current,
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'
import { tokenConfig } from '../../components/auth/authSlice';
import { getCompanyList, getCompanyById, createCompany, updateCompany, deleteCompany } from './companiesSlice'
import { v4 as uuidv4 } from 'uuid'


// Configure axios
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_USERS_SERVICE_API_DOMAIN
});

// Define the entity adapter
const jobBoardsAdapter = createEntityAdapter({
    selectId: ({ id }) => id,
})

// Define the initial state using the adapter
const initialState = jobBoardsAdapter.getInitialState({
    status: 'idle',
    errors: {},
    ids: [],
})

// Create the slice
const jobBoardsSlice = createSlice({
  name: 'jobBoards',
  initialState,
  reducers: {
      clearErrors: (state) => {
          state.errors = {}
      },
      addJobBoard(state, action) {
        const job_board = { id: uuidv4(), company: action.payload, companyId: action.payload }
        jobBoardsAdapter.upsertOne(state, job_board)
      }
  },
  extraReducers(builder) {
    builder
      // Get Company List
      .addCase(getCompanyList.fulfilled, (state, action) => {
        const job_boards = action.payload.map((company) =>
            company.job_boards.map((job_board) => ({ ...job_board, companyId: company.id }))
          )
          .flat()
        jobBoardsAdapter.upsertMany(state, job_boards)
        state.errors = {}
        state.status = 'idle'
      })

      // Get Company By Id
      .addCase(getCompanyById.fulfilled, (state, action) => {
        const job_boards = action.payload.job_boards
          .map((job_board) => ({ ...job_board, companyId: action.payload.id }))
          .flat()
          jobBoardsAdapter.upsertMany(state, job_boards)
        state.errors = {}
        state.status = 'idle'
      })

      // Create Company
      .addCase(createCompany.fulfilled, (state, action) => {
        const job_boards = action.payload.job_boards
          .map((job_board) => ({ ...job_board, companyId: action.payload.id }))
          .flat()
        state.errors = {}
        state.status = 'idle'
        jobBoardsAdapter.upsertMany(state, job_boards)
      })
      .addCase(createCompany.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.errors = state.errors ? state.errors : {};
        if( action.payload.job_boards ) {
          state.errors = action.payload.job_boards[0]
        }
        state.status = 'idle'
      })

      // Update Company
      .addCase(updateCompany.fulfilled, (state, action) => {
        const job_boards = action.payload.job_boards
          .map((job_board) => ({ ...job_board, companyId: action.payload.id }))
          .flat()
        state.errors = {}
        state.status = 'idle'
        jobBoardsAdapter.upsertMany(state, job_boards)
      })
      .addCase(updateCompany.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(updateCompany.rejected, (state, action) => {
          const { id, errors } = action.payload
          state.errors = state.errors ? state.errors : {};
          if(errors.job_boards) {
            state.errors = errors.job_boards[0]
          }
          state.status = 'idle'
      })

      // Delete Company
      .addCase(deleteCompany.fulfilled, (state, action) => {
        const currentState = Object.values( current(state.entities) );
        const deletedJobBoards = currentState
                                    .filter(job_board => job_board.company === action.meta.arg)
                                    .map(job_board => job_board.id)
        jobBoardsAdapter.removeMany(state, deletedJobBoards)
      })
  }
})

export const { addJobBoard } = jobBoardsSlice.actions

export default jobBoardsSlice.reducer


export const {
  selectAll: selectAllJobBoards,
  selectById: selectJobBoardById,
  selectIds: selectJobBoardIds,
} = jobBoardsAdapter.getSelectors((state) => state.jobBoardsSlice)

// SELECTOR: Get validation errors
export const selectErrors = (state) => {
  return state.jobBoardsSlice.errors
}

export const selectJobBoardsByCompany = createSelector(
  [selectAllJobBoards, (state, company) => company],
  (job_boards, company) => job_boards.filter((job_board) => job_board.company === company.id)
)
