import axios from 'axios';
import { tokenConfig } from '../../components/auth/authSlice';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'


// Configure axios
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_USERS_SERVICE_API_DOMAIN
});


// Define the entity adapter
const indeedSearchesAdapter = createEntityAdapter({
  selectId: ({ id }) => id,
})

// Define the initial state using the adapter
const initialState = indeedSearchesAdapter.getInitialState({
  status: 'idle',
  errors: {},
})

// Retrieve the list of indeed searches
export const getIndeedSearchList = createAsyncThunk(
  'indeedSearch/getList',
  async (_, thunkAPI) => {
      const { getState } = thunkAPI;
      try {
          console.log('getIndeedSearchList has been triggered....')

          const response = await axiosInstance.get(`/api/indeed/`, tokenConfig(getState))
          return await response.data
      } catch(err) {
          return thunkAPI.rejectWithValue(err.response.data)
      }
  }
)

// Get a single indeed search
export const getIndeedSearchById = createAsyncThunk(
  'indeedSearch/getIndeedSearchById',
  async (recordId, thunkAPI) => {
      const { dispatch, getState } = thunkAPI;
      try {
          const response = await axiosInstance.get(`/api/indeed/${recordId}/`, tokenConfig(getState))
          return await response.data
      } catch(err) {
          return thunkAPI.rejectWithValue(err.response.data)
      }
  }
)

// Create a new indeed search
export const createIndeedSearch = createAsyncThunk(
  'indeedSearch/createIndeedSearch',
  async (record, thunkAPI) => {
      const { dispatch, getState } = thunkAPI;
      try {
          const response = await axiosInstance.post(`/api/indeed/`, record, tokenConfig(getState))
          return await response.data
      } catch(err) {
          return thunkAPI.rejectWithValue(err.response.data)
      }
  }
)

// Update an indeed search
export const updateIndeedSearch = createAsyncThunk(
  'indeedSearch/updateIndeedSearch',
  async (record, thunkAPI) => {
    const { getState } = thunkAPI;
    try {
      const response = await axiosInstance.put(`/api/indeed/${record.id}/`, record, tokenConfig(getState))
      return await response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ id: record.id, errors: err.response.data })
    }
  }
)

// Delete an indeed search
export const deleteIndeedSearch = createAsyncThunk(
  'indeedSearch/deleteIndeedSearch',
  async (recordId, thunkAPI) => {
      const { dispatch, getState } = thunkAPI;
      try {
          const response = await axiosInstance.delete(`/api/indeed/${recordId}/`, tokenConfig(getState))
          return await response.data
      } catch(err) {
          return thunkAPI.rejectWithValue(err.response.data)
      }
  }
)

// Scrape an Indeed Search
export const scrapeIndeedSearch = createAsyncThunk(
  'indeedSearch/scrapeIndeedSearch',
  async (record, thunkAPI) => {
      const { dispatch, getState } = thunkAPI;
      try {
        const response = await axiosInstance.post(`/api/scrape/indeed/`, record, tokenConfig(getState))
        return await response.data
      } catch(err) {
        return thunkAPI.rejectWithValue(err.response.data)
      }
  }
)


// Create the slice
const indeedSearchesSlice = createSlice({
  name: 'indeedSearches',
  initialState,
  reducers: {
      clearErrors: (state) => {
          state.errors = {}
      }
  },
  extraReducers(builder) {
      builder
          // Indeed Search List
          .addCase(getIndeedSearchList.fulfilled, (state, action) => {
              const records = action.payload
              indeedSearchesAdapter.upsertMany(state, records)
              state.status = 'idle'
          })
          .addCase(getIndeedSearchList.pending, (state, action) => {
              state.status = 'loading'
          })
          .addCase(getIndeedSearchList.rejected, (state, action) => {
              state.status = 'idle'
          })

          // Get Indeed Search by id
          .addCase(getIndeedSearchById.fulfilled, (state, action) => {
              const record = action.payload
              indeedSearchesAdapter.upsertOne(state, record)
              state.status = 'idle'
          })
          .addCase(getIndeedSearchById.pending, (state, action) => {
              state.status = 'loading'
          })
          .addCase(getIndeedSearchById.rejected, (state, action) => {
              state.status = 'idle'
          })

          // Create Indeed Search
          .addCase(createIndeedSearch.fulfilled, (state, action) => {
            const record = action.payload
            state.status = 'idle'
            delete state.errors.createIndeedSearch
            indeedSearchesAdapter.addOne(state, record)
          })
          .addCase(createIndeedSearch.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(createIndeedSearch.rejected, (state, action) => {
            state.errors.createIndeedSearch = action.payload
            state.status = 'idle'
          })

          // Update Indeed Search
          .addCase(updateIndeedSearch.fulfilled, (state, action) => {
              const record = action.payload
              if (state.errors.updateIndeedSearch) {
                  delete state.errors.updateIndeedSearch[record.id]
              }
              state.status = 'idle'
              indeedSearchesAdapter.upsertOne(state, record)
          })
          .addCase(updateIndeedSearch.pending, (state, action) => {
              state.status = 'loading'
          })
          .addCase(updateIndeedSearch.rejected, (state, action) => {
              const { id, errors } = action.payload
              if (!state.errors.updateIndeedSearch) {
                  state.errors.updateIndeedSearch = {}
              }
              state.errors.updateIndeedSearch[id] = errors
              state.status = 'idle'
          })

          // Delete Indeed Search
          .addCase(deleteIndeedSearch.fulfilled, (state, action) => {
              state.errors = {}
              state.status = 'idle'
              indeedSearchesAdapter.removeOne(state, action.meta.arg)
          })
          .addCase(deleteIndeedSearch.pending, (state, action) => {
              state.status = 'loading'
          })
          .addCase(deleteIndeedSearch.rejected, (state, action) => {
              state.errors = action.payload
              state.status = 'idle'
          })

          // Scrape Indeed Search
          .addCase(scrapeIndeedSearch.fulfilled, (state, action) => {
            state.errors = {}
            state.status = 'idle'
          })
          .addCase(scrapeIndeedSearch.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(scrapeIndeedSearch.rejected, (state, action) => {
            state.errors = action.payload
            state.status = 'idle'
          })
  }
})

export default indeedSearchesSlice.reducer

// Export the actions
export const { clearErrors } = indeedSearchesSlice.actions

export const {
  selectAll: selectAllIndeedSearches,
  selectById: selectIndeedSearchById,
} = indeedSearchesAdapter.getSelectors((state) => state.indeedSearchesSlice)

// SELECTOR: Get validation errors
export const selectErrors = (state) => {
  return state.indeedSearchesSlice.errors
}