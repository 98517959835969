import axios from 'axios';
import { tokenConfig } from '../../components/auth/authSlice';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'


// Configure axios
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_USERS_SERVICE_API_DOMAIN
});

// Define the entity adapter
const scrapeSummaryAdapter = createEntityAdapter({
    selectId: ({ id }) => id,
})

// Define the initial state using the adapter
const initialState = scrapeSummaryAdapter.getInitialState({
    status: 'idle',
    errors: {},
})

// Retrieve the list of job scrapes that have been run in the last 7 days
export const getScrapeSummaryList = createAsyncThunk(
    'scrape-summary/getScrapeSummaryList',
    async (_, thunkAPI) => {
        const { getState } = thunkAPI;
        try {
            const response = await axiosInstance.get(`/api/summary/scrape/list?limit=7`, tokenConfig(getState))
            return await response.data
        } catch(err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)


// Create the slice
const scrapeSummarySlice = createSlice({
  name: 'scrapeSummary',
  initialState,
  reducers: {
      clearErrors: (state) => {
          state.errors = {}
      }
  },
  extraReducers(builder) {
      builder
          // Scrape Summary List
          .addCase(getScrapeSummaryList.fulfilled, (state, action) => {
              const scrapesList = action.payload
              for(let i = 0; i < scrapesList.length; i++) {
                  scrapesList[i].summary_job_results_count = scrapesList[i].summary_job_results.length
              }
              scrapeSummaryAdapter.upsertMany(state, scrapesList)
              state.status = 'idle'
          })
          .addCase(getScrapeSummaryList.pending, (state, action) => {
              state.status = 'loading'
          })
          .addCase(getScrapeSummaryList.rejected, (state, action) => {
              state.status = 'idle'
          })
  }
})

export default scrapeSummarySlice.reducer

// Export the actions
export const { clearErrors } = scrapeSummarySlice.actions

export const {
  selectAll: selectAllScrapeSummary,
  selectById: selectScrapeSummaryById,
} = scrapeSummaryAdapter.getSelectors((state) => state.scrapeSummarySlice)

// SELECTOR: Get validation errors
export const selectErrors = (state) => {
  return state.scrapeSummarySlice.errors
}