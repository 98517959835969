import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import authReducer from '../components/auth/authSlice';
import industryReducer from '../features/industries/industriesSlice';
import companyReducer from '../features/companies/companiesSlice';
import indeedSearchReducer from '../features/indeed/indeedSlice';
import jobBoardReducer from '../features/companies/jobBoardsSlice';
import scrapeSummaryReducer from '../features/dashboard/scrapeSummarySlice';
import summaryJobResultsReducer from '../features/dashboard/summaryJobResultsSlice';
import jobPostsReducer from '../features/jobPosts/jobPostsSlice';
import jobPostKeywordsReducer from '../features/jobPosts/jobPostKeywordsSlice';

export const rootReducer = {
    authSlice: authReducer,
    industriesSlice: industryReducer,
    companiesSlice: companyReducer,
    indeedSearchesSlice: indeedSearchReducer,
    jobBoardsSlice: jobBoardReducer,
    scrapeSummarySlice: scrapeSummaryReducer,
    summaryJobResultsSlice: summaryJobResultsReducer,
    jobPostsSlice: jobPostsReducer,
    jobPostKeywordsSlice: jobPostKeywordsReducer,
}

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
