import axios from 'axios';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'


// Configure axios
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_USERS_SERVICE_API_DOMAIN
});


const authAdapter = createEntityAdapter({
  //selectId: ({ id }) => id,
  selectId: (entity) => entity.user ? entity.user.id : entity.id,
})


const initialState = authAdapter.getInitialState({
  status: 'idle',
  errors: {},
  isAuthenticated: false,
  token: localStorage.getItem('token'),
})


// Load user
export const loadUserNew = createAsyncThunk(
  'auth/loadUserNew',
  async (_, thunkAPI) => {
    const { getState } = thunkAPI;

    try {
      const response = await axiosInstance.get(`/api/auth/user`, tokenConfig(getState));
      return response.data;
    } catch(err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
)


// LOGIN USER
export const login = createAsyncThunk(
  'auth/login',
  async (user, thunkAPI) => {
    const { getState } = thunkAPI;
    const { username, password } = user;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // Request Body
    const body = JSON.stringify({ username, password });

    try {
      const response = await axiosInstance.post(`/api/auth/login`, body, config);
      return await response.data;
    } catch(err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
)


// LOGOUT USER
export const logout = createAsyncThunk(
  'auth/logout',
  async (_, thunkAPI) => {
    const { getState } = thunkAPI;

    try {
      const response = await axiosInstance.post(`/api/auth/logout`, null, tokenConfig(getState));
      return response.data;
    } catch(err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
)


// REGISTER USER
export const register = createAsyncThunk(
  'auth/register',
  async (user, thunkAPI) => {
    const { getState } = thunkAPI;
    const { first_name, last_name, username, email, password } = user;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // Request Body
    const body = JSON.stringify({ first_name, last_name, username, email, password });

    try {
      const response = await axiosInstance.post(`/api/auth/register`, body, config);
      return await response.data;
    } catch(err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
)


// UPDATE USER INFORMATION
export const updateUserInfo = createAsyncThunk(
  'auth/updateUserInfo',
  async (user, thunkAPI) => {
    const { getState } = thunkAPI;
    const { first_name, last_name, username, email } = user;

    // Request Body
    const body = JSON.stringify({ first_name, last_name, username, email });

    try {
      const response = await axiosInstance.put(`/api/user/update/${user.id}/`, body, tokenConfig(getState));
      return response.data;
    } catch(err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
)


// UPDATE USER PASSWORD
export const changeUserPassword = createAsyncThunk(
  'auth/changeUserPassword',
  async (payload, thunkAPI) => {
    const { getState } = thunkAPI;
    const { old_password, new_password1, new_password2 } = payload;

    // Request Body
    const body = JSON.stringify({ old_password, new_password1, new_password2 });

    try {
      const response = await axiosInstance.put(`/api/user/change-password/${payload.id}/`, body, tokenConfig(getState));
      return response.data;
    } catch(err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
)


// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  const token = getState().authSlice.token;

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }

  return config;
};


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

    // Load User
    .addCase(loadUserNew.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.status = 'idle';
      authAdapter.setOne(state, action.payload);
    })
    .addCase(loadUserNew.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(loadUserNew.rejected, (state, action) => {
      state.status = 'idle';
    })

    //Login
    .addCase(login.fulfilled, (state, action) => {
      const { user, token } = action.payload;
      state.isAuthenticated = true;
      state.errors = {};
      state.status = 'idle';
      state.token = token;
      localStorage.setItem('token', token);
      authAdapter.addOne(state, user);
    })
    .addCase(login.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(login.rejected, (state, action) => {
      state.errors = action.payload;
      state.isAuthenticated = false;
      state.status = 'idle';
      localStorage.removeItem('token');
    })

    //Logout
    .addCase(logout.fulfilled, (state, action) => {
      state.entities = {};
      state.ids = [];
      state.isAuthenticated = false;
      state.status = 'idle';
      state.token = null;
      localStorage.removeItem('token');
    })
    .addCase(logout.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(logout.rejected, (state, action) => {
      state.status = 'idle';
    })

    // Register
    .addCase(register.fulfilled, (state, action) => {
      const { user, token } = action.payload;
      state.isAuthenticated = true;
      state.status = 'idle';
      state.token = token;
      localStorage.setItem('token', token);
      authAdapter.addOne(state, user);
    })
    .addCase(register.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(register.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.status = 'idle';
      localStorage.removeItem('token');
    })

    // Update user info
    .addCase(updateUserInfo.fulfilled, (state, action) => {
      const user = action.payload.user ? action.payload.user : action.payload;
      authAdapter.upsertOne(state, user);
      state.errors = {};
      state.status = 'idle';
    })
    .addCase(updateUserInfo.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(updateUserInfo.rejected, (state, action) => {
      state.errors = action.payload;
      state.status = 'idle';
    })

    // Change user password
    .addCase(changeUserPassword.fulfilled, (state, action) => {
      const { user, token } = action.payload;
      state.isAuthenticated = true;
      state.errors = {};
      state.token = token;
      localStorage.setItem('token', token);
      authAdapter.upsertOne(state, user);
      state.status = 'idle';
    })
    .addCase(changeUserPassword.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(changeUserPassword.rejected, (state, action) => {
      state.errors = action.payload;
      state.status = 'idle';
    })
  }
});

export default authSlice.reducer


export const {
  selectAll: selectAllAuth,
  selectById: selectAuthById,
} = authAdapter.getSelectors((state) => state.authSlice)

// SELECTOR: is user authenticated?
export const selectIsUserAuthenticated = (state) => {
  return state.authSlice.isAuthenticated
}

// SELECTOR: Get active user id
export const selectActiveAuthUserId = (state) => {
  if(state.authSlice.isAuthenticated !== true) {
    return false;
  } else {
    return state.authSlice.ids[0]
  } 
}

// SELECTOR: Get active user
export const selectActiveAuthUser = createSelector(
  selectAllAuth,
  selectActiveAuthUserId,
  (
    authUsers,
    currentAuthUserId
  ) => {
    return authUsers.find(user => user.id === currentAuthUserId)
  }
)

// SELECTOR: Get validation errors
export const selectErrors = (state) => {
  return state.authSlice.errors
}