import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, selectIsUserAuthenticated, selectErrors } from '../../components/auth/authSlice';

const Login = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        username: "",
        password: ""
    });
    const { username, password } = state;
    const isUserAuthenticated = useSelector((state) => selectIsUserAuthenticated(state))
    const errors = useSelector(selectErrors);

    const loginFormSubmit = (e) => {
        e.preventDefault();
        dispatch(login(state));
    };

    const onChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    if (isUserAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <div className="col-md-6 m-auto">
            <div className="card card-body mt-5">
                <h2 className="text-center">Login</h2>

                {errors.non_field_errors &&
                  <div class="p-2 mb-2 mt-2 text-danger-emphasis bg-danger-subtle border border-danger-subtle rounded-3">
                    {errors.non_field_errors}
                  </div>
                }

                <form onSubmit={loginFormSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="username">Username</label>
                        <input
                            type="text"
                            className={errors.username ? "form-control is-invalid" : "form-control"}
                            id="username"
                            name="username"
                            onChange={onChange}
                            value={username}
                        />
                        {errors.username && <div className="invalid-feedback">{errors.username}</div>}
                    </div>

                    <div className="mb-3">
                        <label className="form-label" htmlFor="password">Password</label>
                        <input
                            type="password"
                            className={errors.password ? "form-control is-invalid" : "form-control"}
                            id="password"
                            name="password"
                            onChange={onChange}
                            value={password}
                            />
                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                    </div>

                    <div className="mb-3">
                        <button type="submit" className="btn btn-primary">
                            Login
                        </button>
                    </div>
                    <p>Forgot your password? Reset it <Link to="/password-reset">here</Link></p>
                </form>
            </div>
        </div>
    )
}

export default Login