import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingScreen from './LoadingScreen';

function PrivateRoute({ children }) {
    const auth = useSelector(store => store.authSlice);

    if( auth.status == 'loading' ) {
        return <LoadingScreen />;
    } else if(!auth.isAuthenticated) {
        return <Navigate to="/login" />;
    } else {
        return children;
    }
}

export default PrivateRoute