import axios from 'axios';
import { tokenConfig } from '../../components/auth/authSlice';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'


// Configure axios
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_USERS_SERVICE_API_DOMAIN
});

// Define the entity adapter
const companiesAdapter = createEntityAdapter({
    selectId: ({ id }) => id,
})

// Define the initial state using the adapter
const initialState = companiesAdapter.getInitialState({
    status: 'idle',
    errors: {},
})

// Retrieve the list of companies
export const getCompanyList = createAsyncThunk(
    'companies/getCompanyList',
    async (_, thunkAPI) => {
        const { getState } = thunkAPI;
        try {
            const response = await axiosInstance.get(`/api/companies/`, tokenConfig(getState))
            return await response.data
        } catch(err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

// Get a single company
export const getCompanyById = createAsyncThunk(
    'companies/getCompanyById',
    async (recordId, thunkAPI) => {
        const { dispatch, getState } = thunkAPI;
        try {
            const response = await axiosInstance.get(`/api/companies/${recordId}/`, tokenConfig(getState))
            return await response.data
        } catch(err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

// Create a new company
export const createCompany = createAsyncThunk(
    'companies/createCompany',
    async (record, thunkAPI) => {
        const { dispatch, getState } = thunkAPI;
        try {
            const response = await axiosInstance.post(`/api/companies/`, record, tokenConfig(getState))
            return await response.data
        } catch(err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

// Update a company
export const updateCompany = createAsyncThunk(
  'companies/updateCompany',
  async (record, thunkAPI) => {
    const { getState } = thunkAPI;
    try {
      const response = await axiosInstance.put(`/api/companies/${record.id}/`, record, tokenConfig(getState))
      return await response.data
    } catch (err) {
      return thunkAPI.rejectWithValue({ id: record.id, errors: err.response.data })
    }
  }
)

// Delete a company
export const deleteCompany = createAsyncThunk(
    'companies/deleteCompany',
    async (recordId, thunkAPI) => {
        const { dispatch, getState } = thunkAPI;
        try {
            const response = await axiosInstance.delete(`/api/companies/${recordId}/`, tokenConfig(getState))
            return await response.data
        } catch(err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

// Scrape a company
export const scrapeCompany = createAsyncThunk(
  'companies/scrapeCompany',
  async (record, thunkAPI) => {
      const { dispatch, getState } = thunkAPI;
      try {
        const response = await axiosInstance.post(`/api/scrape/job-board/`, record, tokenConfig(getState))
        return await response.data
      } catch(err) {
        return thunkAPI.rejectWithValue(err.response.data)
      }
  }
)

// Scrape ALL Companies
export const scrapeAllCompanies = createAsyncThunk(
  'companies/scrapeAllCompanies',
  async (_, thunkAPI) => {
      const { dispatch, getState } = thunkAPI;
      try {
          const response = await axiosInstance.post(`/api/scrape/batch-job-board/`, tokenConfig(getState))
          return await response.data
      } catch(err) {
          return thunkAPI.rejectWithValue(err.response.data)
      }
  }
)

// Create the slice
const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        clearErrors: (state) => {
            state.errors = {}
        }
    },
    extraReducers(builder) {
        builder
            // Company List
            .addCase(getCompanyList.fulfilled, (state, action) => {
                const companies = action.payload
                companiesAdapter.upsertMany(state, companies)
                state.status = 'idle'
            })
            .addCase(getCompanyList.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getCompanyList.rejected, (state, action) => {
                state.status = 'idle'
            })

            // Get Company by id
            .addCase(getCompanyById.fulfilled, (state, action) => {
                const company = action.payload
                // delete company.job_boards
                companiesAdapter.upsertOne(state, company)
                state.status = 'idle'
            })
            .addCase(getCompanyById.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getCompanyById.rejected, (state, action) => {
                state.status = 'idle'
            })

            // Create Company
            .addCase(createCompany.fulfilled, (state, action) => {
              const company = action.payload
              state.status = 'idle'
              delete state.errors.createCompany
              companiesAdapter.addOne(state, company)
            })
            .addCase(createCompany.pending, (state, action) => {
              state.status = 'loading'
            })
            .addCase(createCompany.rejected, (state, action) => {
              state.errors.createCompany = action.payload
              state.status = 'idle'
            })

            // Update Company
            .addCase(updateCompany.fulfilled, (state, action) => {
                const company = action.payload
                if (state.errors.updateCompany) {
                    delete state.errors.updateCompany[company.id]
                }
                state.status = 'idle'
                companiesAdapter.upsertOne(state, company)
            })
            .addCase(updateCompany.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateCompany.rejected, (state, action) => {
                const { id, errors } = action.payload
                if (!state.errors.updateCompany) {
                    state.errors.updateCompany = {}
                }
                state.errors.updateCompany[id] = errors
                state.status = 'idle'
            })

            // Delete Company
            .addCase(deleteCompany.fulfilled, (state, action) => {
              state.errors = {}
              state.status = 'idle'
              companiesAdapter.removeOne(state, action.meta.arg)
            })
            .addCase(deleteCompany.pending, (state, action) => {
              state.status = 'loading'
            })
            .addCase(deleteCompany.rejected, (state, action) => {
              state.errors = action.payload
              state.status = 'idle'
            })

            // Scrape Company
            .addCase(scrapeCompany.fulfilled, (state, action) => {
              state.errors = {}
              state.status = 'idle'
            })
            .addCase(scrapeCompany.pending, (state, action) => {
              state.status = 'loading'
            })
            .addCase(scrapeCompany.rejected, (state, action) => {
              state.errors = action.payload
              state.status = 'idle'
            })

            // Scrape All Companies
            .addCase(scrapeAllCompanies.fulfilled, (state, action) => {
              state.errors = {}
              state.status = 'idle'
            })
            .addCase(scrapeAllCompanies.pending, (state, action) => {
              state.status = 'loading'
            })
            .addCase(scrapeAllCompanies.rejected, (state, action) => {
              state.errors = action.payload
              state.status = 'idle'
            })
    }
})

export default companiesSlice.reducer

// Export the actions
export const { clearErrors } = companiesSlice.actions

export const {
    selectAll: selectAllCompanies,
    selectById: selectCompanyById,
} = companiesAdapter.getSelectors((state) => state.companiesSlice)

// SELECTOR: Get validation errors
export const selectErrors = (state) => {
    return state.companiesSlice.errors
}