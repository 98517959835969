import React, { useState, Fragment } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { logout, selectIsUserAuthenticated } from '../auth/authSlice';

const TopNavbar = () => {
  // let dispatch = useDispatch();
  // const user = useSelector(store => store.authSlice.user);
  // const isUserAuthenticated = useSelector((state) => selectIsUserAuthenticated(state))

  // const handleLogoutClick = (e) => {
  //   e.preventDefault();
  //   dispatch(logout());
  // };

    return (
        <header className="navbar sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 text-white" href="#">
                Prospect App
            </a>

            <ul className="navbar-nav flex-row d-md-none">
                <li className="nav-item text-nowrap">
                    <button className="nav-link px-3 text-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSearch"
                        aria-controls="navbarSearch"
                        aria-expanded="false"
                        aria-label="Toggle search">
                    </button>
                </li>

                <li className="nav-item text-nowrap">
                    <button className="nav-link px-3 text-white"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#sidebarMenu"
                        aria-controls="sidebarMenu"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                    </button>
                </li>
            </ul>

            <div className="navbar-search w-100 collapse" id="navbarSearch">
                <input className="form-control w-100 rounded-0 border-0"
                    type="text"
                    placeholder="Search"
                    aria-label="Search" />
            </div>
        </header>
    );
}

export default TopNavbar;