import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createIndustry, selectErrors } from './industriesSlice'

const emptyIndustry = { name: "" }

const RightSidebar = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState(emptyIndustry);
    const errors = useSelector(selectErrors);

    const formSubmit = (e) => {
        e.preventDefault();
        dispatch(createIndustry(state));
        setState(emptyIndustry);
    };
    
    const onChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    return (
        <div className="col-lg-4 d-flex flex-column">
            <div className="row flex-grow">
                <div className="col-md-6 col-lg-12 grid-margin">    
                    <div className="card card-rounded">
                        <div className="card-body">
                            <div className="row">
                                <h4 className="card-title card-title-dash mb-4">Add Industry</h4>
                                <form onSubmit={formSubmit}>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            className={errors.createIndustry ? "form-control is-invalid" : "form-control"}
                                            id="name"
                                            name="name"
                                            onChange={onChange}
                                            value={state.name}
                                        />
                                        {errors.createIndustry && <div className="invalid-feedback">{errors.createIndustry.name}</div>}
                                    </div>

                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-primary">
                                            Create
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RightSidebar