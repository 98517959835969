import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompany, getCompanyById, selectCompanyById, selectErrors } from './companiesSlice'
import { selectJobBoardsByCompany } from './jobBoardsSlice'
import { getIndustryList } from '../industries/industriesSlice'
import JobBoardList from './JobBoardList'
import { v4 as uuidv4 } from 'uuid'


const EditCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const [state, setState] = useState({
      name: '',
      industry: '',
      website_url: '',
      is_unicorn: '0',
      is_deactivated: '0',
      description: ''
  });
  const company = useSelector((state) => selectCompanyById(state, id))
  const errors = useSelector(selectErrors);
  const industries = useSelector(state => state.industriesSlice.entities)
  const jobBoardList = useSelector((state) => selectJobBoardsByCompany(state, company))
  const [jobBoards, setJobBoards] = useState(jobBoardList)


  useEffect(() => {
      dispatch(getCompanyById(id));
      dispatch(getIndustryList());
  }, []);


  useEffect(() => {
      if( company ) {
        setState({ ...company });
      }
  }, [company]);


  useEffect(() => {
    if( !jobBoardList.length ) {
      setJobBoards([emptyJobBoard()])
    } else {
      setJobBoards(jobBoardList)
    }
  }, [jobBoardList]);


  function emptyJobBoard() {
    return { id: uuidv4(), type: "", resource_slug: "", url: "", section_to_check: "" }
  }

  const handleJobBoardInputChange = (e, jobBoard) => {
    let { name, value } = e.target;

    const newList = jobBoards.map((jb) => {
      if( jb.id === jobBoard.id) {
        return { ...jb, [name]: value }
      }
      return jb
    })
    setJobBoards( newList )
  }


  const handleInputChange = (e) => {
      let { name, value } = e.target;
      setState({ ...state, [name]: value });
  }


  const handleFormSubmit = async (e) => {
      e.preventDefault();
      const company = state
      company.job_boards = jobBoards

      const resultAction = await dispatch(updateCompany(company));

      if (updateCompany.fulfilled.match(resultAction)) {
          navigate('/companies')
      }
  }

  return (
    <div className="col-lg-8 d-flex flex-column">
      <div className="row flex-grow">
        <div className="col-12 grid-margin stretch-card">
          <div className="card card-rounded">
            <div className="card-body">
              <h2>Edit Company</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="name">Name</label>
                  <input
                    type="text"
                    className={errors.updateCompany && errors.updateCompany[id]?.name ? "form-control is-invalid" : "form-control"}
                    id="name"
                    name="name"
                    value={state.name}
                    onChange={handleInputChange}
                  />
                  {errors.updateCompany && errors.updateCompany[id]?.name && <div className="invalid-feedback">{errors.updateCompany[id].name}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="industry">Industry</label>
                  <select
                    className={errors.updateCompany && errors.updateCompany[id]?.industry ? "form-control is-invalid" : "form-control"}
                    id="industry"
                    name="industry"
                    value={state.industry || ''}
                    onChange={handleInputChange}
                  >
                    <option value="">Not Assigned</option>
                    {Object.values(industries).map(industry => (
                      <option key={industry.id} value={industry.id}>
                        {industry.name}
                      </option>
                    ))}
                  </select>
                  {errors.updateCompany && errors.updateCompany[id]?.industry && <div className="invalid-feedback">{errors.updateCompany[id].industry}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="website_url">Company Website</label>
                  <input
                    type="text"
                    className={errors.updateCompany && errors.updateCompany[id]?.website_url ? "form-control is-invalid" : "form-control"}
                    id="website_url"
                    name="website_url"
                    value={state.website_url}
                    onChange={handleInputChange}
                  />
                  {errors.updateCompany && errors.updateCompany[id]?.website_url && <div className="invalid-feedback">{errors.updateCompany[id].website_url}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="is_unicorn">Is Unicorn?</label>
                  <div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_unicorn"
                        id="is_unicorn_yes"
                        value="1"
                        checked={state.is_unicorn == '1'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_unicorn_yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_unicorn"
                        id="is_unicorn_no"
                        value="0"
                        checked={state.is_unicorn == '0'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_unicorn_no">
                        No
                      </label>
                    </div>
                  </div>
                  {errors.updateCompany && errors.updateCompany[id]?.is_unicorn && <div className="invalid-feedback">{errors.updateCompany[id].is_unicorn}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="is_deactivated">Is Deactivated?</label>
                  <div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_deactivated"
                        id="is_deactivated_yes"
                        value="1"
                        checked={state.is_deactivated == '1'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_deactivated_yes">
                        Yes
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_deactivated"
                        id="is_deactivated_no"
                        value="0"
                        checked={state.is_deactivated == '0'}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="is_deactivated_no">
                        No
                      </label>
                    </div>
                  </div>
                  {errors.updateCompany && errors.updateCompany[id]?.is_deactivated && <div className="invalid-feedback">{errors.updateCompany[id].is_deactivated}</div>}
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="description">Description</label>
                  <textarea
                    className={errors.updateCompany && errors.updateCompany[id]?.description ? "form-control is-invalid" : "form-control"}
                    name="description"
                    value={state.description}
                    onChange={handleInputChange}
                    rows={4}
                  />
                  {errors.updateCompany && errors.updateCompany[id]?.description && <div className="invalid-feedback">{errors.updateCompany[id].description}</div>}
                </div>


                <JobBoardList
                  jobBoardsList={jobBoards}
                  handleJobBoardInputChange={handleJobBoardInputChange}
                />


                <div className="pt-3">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCompany