import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectJobBoardById, addJobBoard } from './jobBoardsSlice';
import { v4 as uuidv4 } from 'uuid';
import JobBoard from './JobBoard';


const JobBoardList = ({
  jobBoardsList,
  handleJobBoardInputChange
}) => {

  let { id } = useParams();
  const dispatch = useDispatch();

  return (
    <div className="pt-3 mb-3 job-board-list">
      <h4>Job Board</h4>

      {jobBoardsList.map((jobBoard, index) => {

        return (
          <JobBoard
            key={jobBoard.id}
            jobBoardId={jobBoard.id}
            jobBoard={jobBoard}
            handleJobBoardInputChange={handleJobBoardInputChange}
          />
        );

      })}
    </div>
  );
}

export default JobBoardList;