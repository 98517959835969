import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUserAuthenticated } from '../../../components/auth/authSlice';
import axios from 'axios';


const RequestPasswordResetForm = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        email: ""
    });
    const { email } = state;  
    
    const isUserAuthenticated = useSelector((state) => selectIsUserAuthenticated(state))
    const [passwordResetRequested, setPasswordResetRequested] = useState(false)
    const [errors, setErrors] = useState({});

    const onChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const requestPasswordFormSubmit = (e) => {
        e.preventDefault();
        requestPasswordReset();
    };

    async function requestPasswordReset() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        // Request Body
        const body = JSON.stringify({ email });

        try {
            const response = await axios.post(process.env.REACT_APP_USERS_SERVICE_API_DOMAIN + `/api/auth/password-reset/`, body, config)
            setPasswordResetRequested(true);
            setErrors({});
        } catch(err) {
            setPasswordResetRequested(false);
            setErrors(err.response.data);
        }
    }

    if (isUserAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    let pageBody;
    if( passwordResetRequested ) {
        pageBody = (
            <p>Please check your email. If an account associated with the email address specified exists, instructions for resetting your password have been sent to it.</p>
        )
    } else {
        pageBody = (
            <div className="row">
                <p>Enter your email address below, and we'll email instructions for setting a new one.</p>  
                <form onSubmit={requestPasswordFormSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="email">Email Address *</label>
                        <input
                            type="text"
                            className={errors.email ? "form-control is-invalid" : "form-control"}
                            id="email"
                            name="email"
                            onChange={onChange}
                            value={email}
                            />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>

                    <div className="mb-3">
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        );
    }

    return (
        <div className="col-md-6 m-auto">
            <div className="card card-body mt-5">
                <h2 className="text-center">Forgot your password?</h2>
                {pageBody}
            </div>
        </div>
    )
}

export default RequestPasswordResetForm