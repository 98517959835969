import axios from 'axios';
import { tokenConfig } from '../../components/auth/authSlice';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'

// Set the API URL
const API_URL = process.env.REACT_APP_USERS_SERVICE_API_DOMAIN

// Define the entity adapter
const industriesAdapter = createEntityAdapter({
    selectId: ({ id }) => id,
})

// Define the initial state using the adapter
const initialState = industriesAdapter.getInitialState({
    status: 'idle',
    errors: {},
})

// Retrieve the list of industries
export const getIndustryList = createAsyncThunk(
    'industries/getIndustryList',
    async (_, thunkAPI) => {
        const { getState } = thunkAPI;
        try {
            const response = await axios.get(API_URL + `/api/industries/`, tokenConfig(getState))
            return await response.data
        } catch(err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

// Get a single industry
export const getIndustryById = createAsyncThunk(
    'industries/getIndustryById',
    async (recordId, thunkAPI) => {
        const { dispatch, getState } = thunkAPI;
        try {
            const response = await axios.get(API_URL + `/api/industries/${recordId}/`, tokenConfig(getState))
            return await response.data
        } catch(err) {
            return thunkAPI.rejectWithValue(err.response.data) 
        }
    }
)

// Create a new industry
export const createIndustry = createAsyncThunk(
    'industries/createIndustry',
    async (record, thunkAPI) => {
        const { dispatch, getState } = thunkAPI;
        try {
            const response = await axios.post(API_URL + `/api/industries/`, record, tokenConfig(getState))
            return await response.data
        } catch(err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)

// Update an industry
export const updateIndustry = createAsyncThunk(
    'industries/updateIndustry',
    async (record, thunkAPI) => {
        const { getState } = thunkAPI;
        try {
            const response = await axios.put(API_URL + `/api/industries/${record.id}/`, record, tokenConfig(getState))
            return await response.data
        } catch (err) {
            return thunkAPI.rejectWithValue({ id: record.id, errors: err.response.data })
        }
    }
)

// Delete an industry
export const deleteIndustry = createAsyncThunk(
    'industries/deleteIndustry',
    async (recordId, thunkAPI) => {
        const { dispatch, getState } = thunkAPI;
        try {
            const response = await axios.delete(API_URL + `/api/industries/${recordId}/`, tokenConfig(getState))
            return await response.data
        } catch(err) {
            return thunkAPI.rejectWithValue(err.response.data)
        }
    }
)


// Create the slice
const industriesSlice = createSlice({
    name: 'industries',
    initialState,
    reducers: {
        clearErrors: (state) => {
            state.errors = {}
        }
    },
    extraReducers(builder) {
        builder
            // Industry List
            .addCase(getIndustryList.fulfilled, (state, action) => {
                const industries = action.payload
                industriesAdapter.upsertMany(state, industries)
                state.status = 'idle'
            })
            .addCase(getIndustryList.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getIndustryList.rejected, (state, action) => {
                state.status = 'idle'
            })

            // Get Industry by id
            .addCase(getIndustryById.fulfilled, (state, action) => {
                const industry = action.payload
                industriesAdapter.upsertOne(state, industry)
                state.status = 'idle'
            })
            .addCase(getIndustryById.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getIndustryById.rejected, (state, action) => {
                state.status = 'idle'
            })

            // Create Industry
            .addCase(createIndustry.fulfilled, (state, action) => {
                const industry = action.payload
                state.status = 'idle'
                delete state.errors.createIndustry
                industriesAdapter.addOne(state, industry)
            })
            .addCase(createIndustry.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(createIndustry.rejected, (state, action) => {
                state.errors.createIndustry = action.payload
                state.status = 'idle'
            })

            // Update Industry
            .addCase(updateIndustry.fulfilled, (state, action) => {
                const industry = action.payload
                if (state.errors.updateIndustry) {
                    delete state.errors.updateIndustry[industry.id]
                }
                state.status = 'idle'
                industriesAdapter.upsertOne(state, industry)
            })
            .addCase(updateIndustry.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateIndustry.rejected, (state, action) => {
                const { id, errors } = action.payload
                if (!state.errors.updateIndustry) {
                    state.errors.updateIndustry = {}
                }
                state.errors.updateIndustry[id] = errors
                state.status = 'idle'
            })

            // Delete Industry
            .addCase(deleteIndustry.fulfilled, (state, action) => {
                state.errors = {}
                state.status = 'idle'
                industriesAdapter.removeOne(state, action.meta.arg)
            })
            .addCase(deleteIndustry.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(deleteIndustry.rejected, (state, action) => {
                state.errors = action.payload
                state.status = 'idle'
            })

    }
})

export default industriesSlice.reducer

// Export the actions
export const { clearErrors } = industriesSlice.actions

export const {
    selectAll: selectAllIndustries,
    selectById: selectIndustryById,
} = industriesAdapter.getSelectors((state) => state.industriesSlice)

// SELECTOR: Get validation errors
export const selectErrors = (state) => {
    return state.industriesSlice.errors
}