import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getScrapeSummaryList,
  selectAllScrapeSummary,
  selectErrors
} from './scrapeSummarySlice'
import ScrapeSummaryList from './ScrapeSummaryList'
import { selectJobPostRecordsBySummary } from './summaryJobResultsSlice'



const Dashboard = (props) => {
  const h1_message = 'Dashboard';
  const dispatch = useDispatch();
  const summaryList = useSelector(selectAllScrapeSummary);
  const [selectedSummary, setSelectedSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getScrapeSummaryList())
    .then((fetchedSummaryList) => {
      if (fetchedSummaryList.payload.length > 0) {
        setSelectedSummary(fetchedSummaryList.payload[0]);
      }
      setIsLoading(false);
    });
  }, [dispatch]);

  const handleSummaryRowClick = (e, summary) => {
    setSelectedSummary(summary);
  }
  const jobPostRecords = useSelector(state => selectJobPostRecordsBySummary(state, selectedSummary));

  let jobPostsContainer = '';
  if( selectedSummary ) {

    jobPostsContainer = (
      <div className="row mt-4">
        <div className="col-lg-10 d-flex flex-column">
          <div className="row flex-grow">
            <div className="col-12 grid-margin stretch-card">
              <div className="card card-rounded">
                <div className="card-body">
                  <div className="d-sm-flex justify-content-between align-items-start">
                    <div>
                      <h2 className="card-title card-title-dash">
                        Job Posts for: <span className="text-capitalize">{selectedSummary.scrape_service}</span> on {selectedSummary.timestamp_from_sns_notif}
                      </h2>
                    </div>
                  </div>

                  <table className="table table-striped table-sm">
                    <thead>
                        <tr>
                          <th scope="col">Company</th>
                          <th scope="col">Job Title</th>
                          <th scope="col">Location</th>
                          <th scope="col">View Job Post</th>
                        </tr>
                    </thead>
                    <tbody>
                      {jobPostRecords.length === 0 &&
                        <tr>
                          <td colSpan="4">No records found</td>
                        </tr>
                      }
                      {jobPostRecords.map((record) => (
                        <tr key={record.id}>
                          <td>{record.job_post_company_name}</td>
                          <td>{record.job_post_title}</td>
                          <td>{record.job_post_location}</td>
                          <td>
                            <Link to={"/job-posts/view/"+record.job_post_id} className="">
                              View Job Details
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  if (isLoading) {
    return (
      <Fragment>
        <h1>{h1_message}</h1>
        <p>Loading...</p>
      </Fragment>
    )
  } else {
    return (
      <div>
        <div className="row">
          <div className="col-lg-10 d-flex flex-column">
            <div className="row flex-grow">
              <div className="col-12 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start">
                      <div>
                        <h1 className="card-title card-title-dash">Recent Searches</h1>
                      </div>
                    </div>

                    <table className="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th scope="col">Service</th>
                          <th scope="col">Scrape Date</th>
                          <th scope="col">Results Count</th>
                        </tr>
                      </thead>

                      <ScrapeSummaryList
                        summaryList={summaryList}
                        handleSummaryRowClick={handleSummaryRowClick}
                      />
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {jobPostsContainer}
      </div>
    )
  }
}

export default Dashboard